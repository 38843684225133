const getSettings = (right, left) => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: right,
    prevArrow: left,
  }
  return settings
}

const subHeading =
  "Tell the world you’re part of a flourishing company. Or your team is flourishing. Or simply that you’re flourishing too. You’re welcome to download and share* these specially made shoutouts."

const images = require.context(
  "../../images/home/mediakit/socialposts",
  false,
  /\.(png|jpe?g|svg)$/
)

const socialMediaPosts = [
  {
    name: "for flourishing sake",
    imgSrc: images("./social_media_1.png").default,
  },
  {
    name: "We're a flourishing team",
    imgSrc: images("./social_media_2.png").default,
  },
  {
    name: "keep flourishing and carry on",
    imgSrc: images("./social_media_3.png").default,
  },
  {
    name: "Thank god I'm flourishing",
    imgSrc: images("./social_media_4.png").default,
  },
  {
    name: "I'm happier when I flourish",
    imgSrc: images("./social_media_5.png").default,
  },
  {
    name: "We love flourishing",
    imgSrc: images("./social_media_6.png").default,
  },
  {
    name: "come flourishing with me",
    imgSrc: images("./social_media_7.png").default,
  },
  {
    name: "choose flourishing",
    imgSrc: images("./social_media_8.png").default,
  },
  { name: "just flourish", imgSrc: images("./social_media_9.png").default },
]

const bannerImages = require.context(
  "../../images/home/mediakit/banners",
  false,
  /\.(png|jpe?g|svg)$/
)

const banners = [
  {
    name: "I'm happier when I flourish",
    imgSrc: bannerImages("./banner_1.png").default,
  },
  {
    name: "We're a flourishing company",
    imgSrc: bannerImages("./banner_2.png").default,
  },
  {
    name: "Choose Flourishing",
    imgSrc: bannerImages("./banner_3.png").default,
  },
]
export { subHeading, socialMediaPosts, banners, getSettings }
