import React from "react"
import "./flourishing-at-work-social.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MediaKit from "../components/media-kit/media-kit"

const MediaKitPage = () => {
  return (
    <Layout>
      <SEO title="Flourishing at work" />
      <MediaKit />
    </Layout>
  )
}

export default MediaKitPage
