import React, { useEffect, useState } from "react"
import "./media-kit.scss"
import { banners, getSettings, socialMediaPosts, subHeading } from "./helper"
import DownloadButton from "../../images/home/mediakit/download-button.svg"
import ArrowRight from "../../images/learnmore/arrowRight-light.svg"
import ArrowLeft from "../../images/learnmore/arrowLeft-light.svg"
import Slider from "react-slick"

const MediaKit = () => {
  const [isMobile, setIsMobile] = useState(false)

  const handleDownload = (imgSrc, imgName) => {
    const link = document.createElement("a")
    link.href = imgSrc
    link.download = `${imgName}.png`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  let settings = getSettings(
    <img src={ArrowRight} alt="next" style={{ right: "40px !important" }} />,
    <img src={ArrowLeft} alt="prev" style={{ left: "40px !important" }} />
  )

  useEffect(() => {
    const checkIsMobile = () => {
      if (typeof window !== "undefined") {
        setIsMobile(window.matchMedia("(max-width: 786px)").matches)
      }
    }

    checkIsMobile()

    if (typeof window !== "undefined") {
      window.addEventListener("resize", checkIsMobile)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", checkIsMobile)
      }
    }
  }, [])

  // const isMobile =
  //   typeof window !== `undefined` &&
  //   window.matchMedia("(max-width: 786px)").matches

  // // Separate first, middle, and last posts
  // const firstPost = socialMediaPosts[0]
  // const lastPost = socialMediaPosts[socialMediaPosts.length - 1]
  // const middlePosts = socialMediaPosts.slice(1, socialMediaPosts.length - 1)

  return (
    <>
      <div className="mediaKiPageWrapper">
        <h2 className="mediaKitHeading">Flourishing at Work</h2>
        <p className="subHeading">{subHeading}</p>
        <div className="mediaKitWrapper">
          <h3 className="socialMediaHeading">Social Media Posts</h3>
          <div className="postContentWrapper">
            {!isMobile ? (
              socialMediaPosts?.map((post, index) => (
                <div className="postImageWrapper" key={index}>
                  <img src={post.imgSrc} alt={post.name} />
                  <img
                    src={DownloadButton}
                    alt="downloadButton"
                    className="downloadBtn"
                    onClick={() => handleDownload(post.imgSrc, post.name)}
                  />
                </div>
              ))
            ) : (
              <>
                {/* <div className="postImageWrapper">
                  <img src={firstPost.imgSrc} alt={firstPost.name} />
                  <img
                    src={DownloadButton}
                    alt="downloadButton"
                    className="downloadBtn"
                    onClick={() =>
                      handleDownload(firstPost.imgSrc, firstPost.name)
                    }
                  />
                </div> */}

                <Slider {...settings}>
                  {socialMediaPosts?.map((post, index) => (
                    <div className="postImageWrapper" key={index}>
                      <img src={post.imgSrc} alt={post.name} />
                      <img
                        src={DownloadButton}
                        alt="downloadButton"
                        className="downloadBtn"
                        onClick={() => handleDownload(post.imgSrc, post.name)}
                      />
                    </div>
                  ))}
                </Slider>

                {/* <div className="postImageWrapper">
                  <img src={lastPost.imgSrc} alt={lastPost.name} />
                  <img
                    src={DownloadButton}
                    alt="downloadButton"
                    className="downloadBtn"
                    onClick={() =>
                      handleDownload(lastPost.imgSrc, lastPost.name)
                    }
                  />
                </div> */}
              </>
            )}
          </div>
          <h3 className="bannerHeading">Banners</h3>
          <div className="bannerContentWrapper">
            {banners?.map((banner, index) => (
              <div className="bannerImageWrapper" key={index}>
                <img src={banner.imgSrc} alt={banner.name} />
                <img
                  src={DownloadButton}
                  alt="downloadButton"
                  className="downloadBtn"
                  onClick={() => handleDownload(banner.imgSrc, banner.name)}
                />
              </div>
            ))}
          </div>

          <div className="customizationWrapper">
            <h3 className="customizationHeading">
              Customised specially for you
            </h3>
            <p className="customizationSubText">
              Add a logo. Add some words. Say you’re flourishing your way. Email
              us your request and we’ll get in touch.
            </p>
            <div className="pageFooterText">
              *Please do not modify these designs. All rights reserved&copy;.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MediaKit
